import { CreateGameModal } from '../../components/Game/GameBaseEditor';
import { GameBlockEditor } from '../../components/Game/GameBlockEditor';
import { useGameLikeWorkspace } from '../../components/Game/GameCenter';
import { useEditingGameId } from '../../components/Game/GameEditorStore';
import { GamePackDetailModalWorkspaceWrapper } from '../../components/Game/GamePack';
import {
  DeleteGamePackModal,
  DuplicateGamePackModal,
  GamePackEditorWithWorkspaceAdapter,
} from '../../components/Game/GamePack/GamePackEditor';
import { GamePackBlockPreview } from '../../components/Game/GamePackBlockPreview';

export const GamePackCRUDUtility = (): JSX.Element => {
  const [creatingGamePack] = useGameLikeWorkspace('gamePack', 'create');
  const [editingGamePack] = useGameLikeWorkspace('gamePack', 'edit');
  const [deletingGamePack] = useGameLikeWorkspace('gamePack', 'delete');
  const [activeGamePack] = useGameLikeWorkspace('gamePack', 'active');
  const [publishingGamePack] = useGameLikeWorkspace('gamePack', 'publish');
  const [duplicatingGamePack] = useGameLikeWorkspace('gamePack', 'duplicate');
  const [previewGamePack] = useGameLikeWorkspace('gamePack', 'preview');
  const editingGameId = useEditingGameId();
  const [creatingGame] = useGameLikeWorkspace('game', 'create');

  return (
    <>
      {activeGamePack && <GamePackDetailModalWorkspaceWrapper />}
      {(creatingGamePack || editingGamePack || publishingGamePack) && (
        <GamePackEditorWithWorkspaceAdapter />
      )}
      {deletingGamePack && <DeleteGamePackModal />}
      {duplicatingGamePack && <DuplicateGamePackModal />}
      {previewGamePack && <GamePackBlockPreview />}
      {editingGameId && <GameBlockEditor />}
      {creatingGame && <CreateGameModal />}
    </>
  );
};
