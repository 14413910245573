import noop from 'lodash/noop';
import { useEffect, useState } from 'react';

import { MediaFormatVersion } from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { RoleUtils, SessionMode } from '../../types';
import { type Game, GameLikeLabelMap, type GamePack } from '../../types/game';
import { LPLogo } from '../icons/LPLogo';
import { Loading } from '../Loading';
import { BlockEditorStoreProvider } from '../RoutedBlock';
import { useUser } from '../UserContext';
import { BlockEditor, useOndRecordingDetails } from './Blocks/Common/Editor';
import { BlockCard } from './GameBlockEditor';
import { useGameLikeWorkspace } from './GameCenter';
import { useGameEditorStore } from './GameEditorStore';
import {
  useIsLoadingGameLike,
  useLocalGamePlayStore,
  useLocalLoadedBlocks,
  useLocalLoadedGamePack,
  useLocalLoadedGamePackGames,
  useRefreshLocalBlocksForGamePlay,
} from './GamePlayStore';
import { GameUtils } from './GameUtils';
import {
  useGameSessionBlock,
  useGameSessionStatus,
  usePlayedBlockIds,
} from './hooks';
import { useRecordingDeleteConfirmConfigForGame } from './OndRecordingUtils';
import { GameCover, GamePackCover } from './Utilities';

function Header(props: { game: Game | GamePack; onClose: () => void }) {
  const { game, onClose } = props;

  const isAdmin = RoleUtils.isAdmin(useUser());

  return (
    <div
      className={`w-full h-20 ${
        isAdmin ? 'bg-admin-red' : 'bg-black'
      } px-5 border-secondary border-b flex items-center justify-between`}
    >
      <div className={`flex items-center gap-5`}>
        <LPLogo type={isAdmin ? 'admin' : 'default'} />
        <div
          className={`text-xl text-white flex flex-row justify-start items-center`}
        >
          <div className='w-19 mr-4'>
            {game.type === 'gamePack' ? (
              <GamePackCover pack={game} />
            ) : (
              <GameCover game={game} className='w-18 h-12' />
            )}
          </div>
          <div className='flex flex-col'>
            <p
              className={`${
                game.isPrime ? 'text-red-002' : 'text-white'
              } text-2xs font-bold`}
            >
              {`PREVIEW ${GameLikeLabelMap[game.type]?.toUpperCase()}`}
            </p>
            <div className='flex flex-row items-center'>
              <p className='group-hover:underline'>{game.name}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={`flex items-center gap-5`}>
        <p className='text-red-002 text-sms'>
          Changes will be ignored in preview mode
        </p>
        <button
          onClick={onClose}
          className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
          type='button'
        >
          Close
        </button>
      </div>
    </div>
  );
}

export const GamePackBlockPreview = (): JSX.Element | null => {
  const [gamePack, setGamePack] = useGameLikeWorkspace('gamePack', 'preview');
  const loadedGamePack = useLocalLoadedGamePack();
  const isLoading = useIsLoadingGameLike();
  const games = useLocalLoadedGamePackGames();
  const blocks = useLocalLoadedBlocks(SessionMode.Live);
  const gameSessionStatus = useGameSessionStatus();
  const gameSessionBlock = useGameSessionBlock();
  const playedBlockIds = usePlayedBlockIds();
  const store = useLocalGamePlayStore();
  const [selectedBlockId, setSelectedBlockId] = useState<string | null>(null);
  const selectedBlock = blocks.find((b) => b.id === selectedBlockId) || null;
  const selectedGame = games.find((g) => g.id === selectedBlock?.gameId);
  const firstBlock = blocks[0];
  const gameEditorStore = useGameEditorStore();

  useEffect(() => {
    if (!firstBlock?.id) return;
    setSelectedBlockId((prev) => {
      if (prev) return prev;
      return firstBlock.id || null;
    });
  }, [firstBlock?.id]);

  const expectedOndPlaybackVersion =
    GameUtils.DeriveOndPlaybackVersionFromBlocks(blocks || []);

  const handleClose = useLiveCallback(() => {
    setGamePack(null);
  });

  const asyncDetails = useOndRecordingDetails(
    selectedBlock,
    expectedOndPlaybackVersion,
    useRefreshLocalBlocksForGamePlay(selectedGame?.id),
    false,
    useRecordingDeleteConfirmConfigForGame(selectedGame)
  );

  useEffect(() => {
    if (!gamePack?.id) return;
    if (loadedGamePack?.id === gamePack.id) return;
    if (isLoading) return;

    store.load({ pack: gamePack.id });
  }, [store, gamePack?.id, isLoading, loadedGamePack?.id]);

  if (!gamePack) return null;

  const handleSelect = (blockId: string) => {
    setSelectedBlockId(blockId);
  };

  return (
    <div className='w-screen h-screen bg-black fixed flex flex-col z-50 top-0'>
      <Header game={gamePack} onClose={handleClose} />

      {isLoading ? (
        <div className='w-full flex-1 flex justify-center items-center'>
          <Loading />
        </div>
      ) : (
        <div className='relative w-full flex-1 overflow-auto flex flex-row'>
          <nav className='absolute w-72 h-full bg-modal flex flex-col'>
            <header className='w-full px-4 py-3 flex flex-col gap-1'>
              <div className='w-full flex flex-row items-center justify-between'>
                <p className='font-bold text-white'>Blocks</p>
              </div>
            </header>

            <main className='w-full flex-1 pb-1 overflow-y-auto scrollbar flex flex-col'>
              {games.map((game, index) => {
                return (
                  <div
                    key={game.id}
                    className='w-full flex flex-col items-center'
                  >
                    <header className='relative w-full flex justify-center items-center'>
                      <GameCover
                        game={game}
                        className='w-full opacity-40'
                        imgClassName='rounded-none'
                        imagePriority={[
                          MediaFormatVersion.HD,
                          MediaFormatVersion.Raw,
                        ]}
                      />

                      <div className='absolute text-white text-sm font-bold text-center line-clamp-3'>
                        {game.name}
                      </div>
                    </header>

                    <main className='mt-3 w-full pb-1 flex flex-col gap-1'>
                      {game.blocks?.map((block, index) => {
                        const isPlaying =
                          !!gameSessionStatus &&
                          gameSessionBlock?.id === block.id;
                        const isPlayed = playedBlockIds.includes(block.id);
                        return (
                          <BlockCard
                            key={block.id}
                            index={index}
                            block={block}
                            isSelected={selectedBlockId === block.id}
                            isSelectable={true}
                            isPlaying={isPlaying}
                            isPlayed={isPlayed}
                            isDisabled={false}
                            recordingVersionMismatch={
                              !!block.recording &&
                              block.recording.version !==
                                expectedOndPlaybackVersion
                            }
                            onSelect={() => handleSelect(block.id)}
                          />
                        );
                      })}
                    </main>

                    {index !== games.length - 1 && (
                      <hr className='w-full my-5 border-t-1 border-gray-600' />
                    )}
                  </div>
                );
              })}
            </main>
          </nav>

          <div
            // reset scroll position when switching blocks
            key={selectedBlock?.id}
            className='w-full ml-72 scrollbar overflow-y-auto overflow-x-hidden'
          >
            <div className='p-5 flex items-center justify-end bg-black sticky top-0 left-0 z-3'>
              {asyncDetails.detailsLink}
            </div>
            <div className='mx-4 mb-4'>
              <BlockEditorStoreProvider
                store={gameEditorStore.blockEditorStore}
              >
                <BlockEditor block={selectedBlock} setSavingChanges={noop} />
              </BlockEditorStoreProvider>
            </div>
          </div>
        </div>
      )}

      {asyncDetails.detailsModal}
      {asyncDetails.warningModal}
    </div>
  );
};
